export enum APP_CONSTANS {
  PENDING_SEARCH_WORD = "PENDING_SEARCH_WORD",
  AUTH_USER_DATA = "EL_AUTH_USER_DATA",
  AUTH_FUNCIONALITIES = "EL_AUTH_FUNCIONALITIES",
  TXK_NIUBIZ = "TRANSACTION_TOKEN_NIUBIZ",
  SOCIAL_REQUEST = "SOCIAL_REQUEST",
  CURRENT_IMAGE_EDIT = "CURRENT_IMAGE_EDIT",
  ACCESS_TOKEN_NIUBIZ = "ACCESS_TOKEN_NIUBIZ",
  PURCHASE_NUMBER_NIUBIZ = "PURCHASE_NUMBER_NIUBIZ",
  SESSION_TOKEN_NIUBIZ = "SESSION_TOKEN_NIUBIZ",
  AMOUNT_NIUBIZ = "AMOUNT_NIUBIZ",
  ACTION_PAGE_NIUBIZ = "ACTION_PAGE_NIUBIZ",
}
